import { Typography,Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Header from './Header';
import { Api_url } from './helper';

export const Records = () => {
	const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch users when the component mounts
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      // Make a GET request to fetch all users
      const response = await axios.get(`${Api_url}/sign/users`);
		setUsers(response.data.data);
		console.log(response.data.data)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users. Please try again later.');
      setLoading(false);
    }
  };
  return (
	  <div>
		 <Header/>
		  <Grid container lg={12} style={{background:'#EFEFEF',justifyContent:'center'}}>
			  <Grid container lg={10}>
			  <Typography style={{fontSize:"40px",fontFamily:"Space Grotesk",fontWeight:700}}>My Records</Typography>  
			  </Grid>
			  <Grid container lg={10}>
			  {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <TableContainer component={Paper} style={{ borderRadius: '40px 40px 40px 40px',marginTop:'76px',marginBottom:'76px' }}>
        <Table style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '24px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal' }}>
          <TableHead style={{ background: 'var(--Brand-Dark-Blue, #053F5C)',color:'#EFEFEF',fontSize:'24px'}}>
							  <TableRow>
							  <TableCell style={{color:'#EFEFEF',fontSize:'24px'}}>Number</TableCell>				  
              <TableCell style={{color:'#EFEFEF',fontSize:'24px'}}>Name</TableCell>
              <TableCell style={{color:'#EFEFEF',fontSize:'24px'}}>Email</TableCell>
								  <TableCell style={{ color: '#EFEFEF', fontSize: '24px' }}>Contact Number</TableCell>
								  <TableCell style={{ color: '#EFEFEF', fontSize: '24px' }}>UserType</TableCell>
								  <TableCell style={{color:'#EFEFEF',fontSize:'24px'}}>location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
		  {users.map((user, index) => (
  <TableRow key={user._id}>
    <TableCell style={{fontSize:'20px'}}>{index + 1}</TableCell> {/* Display the index number */}
    <TableCell style={{fontSize:'20px'}}>{user.Name}</TableCell>
    <TableCell style={{fontSize:'20px'}}>{user.Email}</TableCell>
    <TableCell style={{fontSize: '20px'}}>{user.contactnumber}</TableCell>
				  <TableCell style={{ fontSize: '20px' }}>{user.userType}</TableCell>
				  <TableCell style={{ fontSize: "20px" }}>{user.location}</TableCell>
  </TableRow>
))}

          </TableBody>
        </Table>
      </TableContainer>
			  </Grid>
</Grid>
	</div>
  )
}
